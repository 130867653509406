import React, { useEffect } from "react";
// import { useState } from "react";
import styles from "./search-result-widget.module.scss";
import TGSearch from "../../../../shared/tg-search";
import { Card, Offcanvas, OffcanvasHeader } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { globalSearchPreRenderDataRequest } from "../../slice/header/header-slice";
import { RootState } from "../../slice/RootReducer";
import TGPills from "../../../../shared/tg-pills";
import { LookingForAnyOfThesePagesCard } from "../../models/models";
import TGIcon from "../../../../shared/tg-icon";
import {
  getGlobalSearchDataFailure,
  getGlobalSearchDataSuccess,
} from "../../slice/globalSearchSlice";
import { config } from "../../../../config/global";
import { useTranslation } from "react-i18next";
import useScreenSize from "../../../../utils/hook/useScreenSize";

interface SearchResultWidgetProps {
  show: boolean;
  onClose: () => void;
}

const SearchResultWidget: React.FC<SearchResultWidgetProps> = ({
  show,
  onClose,
}) => {
  const dispatch = useDispatch();
  const globalSearchData = useSelector(
    (state: RootState) => state?.osciReducer?.globalSearch.globalSearchDetails
  );
  const { i18n, t } = useTranslation();
  const isDesktopView: boolean = useScreenSize().deviceSize.width > 1080;

  const GLOBAL_SEARCH_API_URL = config.TEAMSITE_GLOBAL_SEARCH_API;

  useEffect(() => {
    dispatch({
      type: config.FETCH_DATA_CONSTANT,
      url: GLOBAL_SEARCH_API_URL,
      isTeamsite: true,
      successAction: getGlobalSearchDataSuccess,
      errorAction: getGlobalSearchDataFailure,
    });
  }, [dispatch]);

  useEffect(() => {
    if (show) {
      document.documentElement.style.setProperty("--body-overflow", "hidden");
    } else if (!show) {
      document.documentElement.style.setProperty("--body-overflow", "auto");
    }

    return () => {
      document.documentElement.style.setProperty("--body-overflow", "auto");
    };
  }, [show]);

  const searchURL =
    process.env.REACT_APP_TG_TEAMSITE_SEARCH_URL +
    i18n.language +
    config.TEAMSITE_SEARCH +
    "?query=";

  const handleClick = (url: string, params?: string) => {
    window.location.href = params ? url + params : url;
  };

  const CardImageOverlay = ({ cardData }: any) => {
    return (
      <span
        onClick={() => handleClick(cardData.reDirURL)}
        className={styles.reDirURL}
      >
        <Card className={styles.customCard}>
          <Card.Img
            src={cardData.image}
            alt={cardData.altText}
            className={styles.customCardImage}
          />
          <Card.ImgOverlay>
            <div className={styles.contentContainer}>
              <div className={styles.titleContainer}>
                <Card.Title className={styles.title}>
                  {cardData.heading}
                </Card.Title>
                <TGIcon icon="arrow-right-bg" fillColor="" size="24" />
              </div>
              <Card.Text className={styles.description}>
                {cardData.description}
              </Card.Text>
            </div>
          </Card.ImgOverlay>
        </Card>
      </span>
    );
  };

  return (
    <Offcanvas
      show={show}
      onHide={onClose}
      placement="top"
      className={`${styles.searchContainer} global-content-padding`}
      backdropClassName={styles.customBackdrop}
      backdrop
      scroll={false}
    >
      <Offcanvas.Header closeButton />
      <Offcanvas.Body>
        <TGSearch
          placeholderText={t("label_search")}
          searchURL={searchURL}
          isDesktopView={isDesktopView}
        />
        <div className={styles.preSearchContainer}>
          <div className={styles.popularKeywordsContainer}>
            <span className={styles.popularKeywordText}>
              {globalSearchData?.popularKeywords?.heading}
            </span>
            <div className={styles.popularKeywordsInnerContainer}>
              {globalSearchData?.popularKeywords?.data.map((a) => (
                <span
                  onClick={() => handleClick(searchURL, a)}
                  className={styles.reDirURL}
                >
                  <TGPills
                    label={a}
                    customClass="global-search-pills"
                    color="#6A6D87"
                    bg="#F2F2FA"
                    fillColor=""
                    variant="primary"
                  />
                </span>
              ))}
            </div>
          </div>
          <div className={styles.lookingForOneOfThesePagesContainer}>
            <span className={styles.textStyle}>
              {globalSearchData?.lookingForAnyOfThesePagesCard?.heading}
            </span>
            <div className={styles.innercontainer}>
              {globalSearchData?.lookingForAnyOfThesePagesCard?.data.map(
                (cardItem: LookingForAnyOfThesePagesCard) => (
                  <CardImageOverlay cardData={cardItem} />
                )
              )}
            </div>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SearchResultWidget;
