import React, { useEffect, useState } from "react";
import axios from 'axios';
import moment from "moment";

const WallettestComponent: React.FC = () => {
  const [passfilebase64, setPassfilebase64] = useState<any>(null);
  const [passfileurl, setPassfileUrl] = useState<any>(null);

   const fetchWalletPkPassApple = (e: React.MouseEvent<HTMLButtonElement>,) => {
    e.stopPropagation();
    var config = {
      method: 'post',
      url: 'https://tgosci-sit.com/profile/rop/generateROPWallet',
      headers: { 
       'Accept':'*/*',
      'Authorization':sessionStorage.getItem('accesstoken'),
      'memberId':sessionStorage.getItem('memberId')
      }
    };
   
    axios(config)
    .then(function (response) {
      console.log(response);
      alert("Apple pass data ready to download");
      setPassfilebase64(response?.data)})
    .catch(function (error) {
      console.log(error);
    });
  };

  const fetchWalletPkPassGoogle = (e: React.MouseEvent<HTMLButtonElement>,) => {
    e.stopPropagation();
    var config = {
      method: 'post',
      url: 'https://tgosci-sit.com/profile/rop/generate-google-wallet-rop',
      headers: { 
       'Accept':'*/*',
      'Authorization':sessionStorage.getItem('accesstoken'),
      'memberId':sessionStorage.getItem('memberId')
      }
    };
   
    axios(config)
    .then(function (response:any) {
      console.log(response);
      alert("Google pass data ready to download");
      setPassfileUrl(response?.data?.value)
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  useEffect(() => {
    moment().month("July").format("M");
  }, []);


  return (
    <div>
     <button onClick={(e) => fetchWalletPkPassGoogle(e)}> Google wallet api call</button>
     <a style={{padding: "5px", marginTop:"20px"}}
     href={passfileurl}>Google wallet pass download</a><br />
     <a style={{padding: "5px", marginTop:"20px",display:"block"}}
     href="https://www.mywallet.co/engine/t/ts.php/pass.pkpass?p_code=109dd271563&p_key=QkIwMDQ0NA==&key=2ca25c0c-d54c-4c7a-98ae-eef5ef6110e2&lang=en">
     Apple wallet legacy</a><br />
     <button onClick={(e) => fetchWalletPkPassApple(e)}> Apple wallet api call</button>
     <a style={{padding: "5px", marginTop:"20px"}}
     href={'data:application/vnd.apple.pkpass;base64,'+ passfilebase64 } >
     Apple wallet Pass Download</a>
    </div>
  );
};

export default WallettestComponent;


