import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { ResetUnlockBanner } from "../../../pages/reset-unlock/banner";
import { Success } from "../../../pages/reset-unlock/success";
import TGIcon from "../../../../../shared/tg-icon";
import TGInput from "../../../../../shared/tg-input";
import TGDateDropdown from "../../../../../shared/tg-date-dropdown";
import TGButtonVariants from "../../../../../shared/tg-button-variants";
import styles from "./unlock-account-form.module.scss";
import { ResetFormData } from "../../../models/models";
import { t } from "i18next";
import { RootState } from "../../../slice/RootReducer";
import { resetDataRequest } from "../../../slice/resetSlice";
import moment from "moment";

const UnlockAccountForm = () => {
  const [error, setErrorCode] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>();
  const [errors, setErrors] = useState<Partial<ResetFormData>>({});
  const [isValid, setIsValid] = useState<boolean>(true);
  const [resetFormData, setFormData] = useState<ResetFormData>({
    name: "",
    surname: "",
    emailAddress: "",
    birthDate: "",
    memberId: "",
  });
  const dispatch = useDispatch();
  const response = useSelector((state: RootState) => state?.osciReducer?.reset);

  useEffect(() => {
    const memberId = sessionStorage.getItem("memberId");
    setFormData({
      ...resetFormData,
      memberId: memberId ? memberId : "",
    });
    if (Object.keys(response.ResetDetails).length > 0) {
      // Checking whether the success condition is getting satisfied
      if (response.ResetDetails.errorCode === "1000") {
        setErrorCode(false);
        setIsSuccess(true);
      } else {
        setIsSuccess(false);
        setErrorCode(true);
      }
    } else if (Object.keys(response.error).length > 0) {
      setErrorCode(true);
      setIsSuccess(false);
    } else {
      setErrorCode(false);
    }
  }, [response.ResetDetails, response.error]);

  const handleSubmitUnlock = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const newErrors: Partial<ResetFormData> = {};
    Object.entries(resetFormData).forEach(([key, value]) => {
      if (!value.trim()) {
        newErrors[key as keyof ResetFormData] = getFieldLabel(
          key as keyof ResetFormData
        );
      } else if (key === "emailAddress" && !isValidEmail(value)) {
        newErrors[key as keyof ResetFormData] = t("reset_invalid_message");
      } else if (
        (key === "name" && resetFormData?.name?.length < 2) ||
        (key === "surname" && resetFormData?.surname?.length < 2)
      ) {
        newErrors[key as keyof ResetFormData] =
          key === "name"
            ? t("error_min_length_first_name")
            : t("error_min_length_last_name");
      } else if (key === "birthDate") {
        const year = value.split("-")[0];
        const month = value.split("-")[1];
        const day = value.split("-")[2];
        if (day == "") {
          newErrors[key as keyof ResetFormData] = t("enroll_date_required");
        } else if (month == "00") {
          newErrors[key as keyof ResetFormData] = t("enroll_month_required");
        } else if (year == "") {
          newErrors[key as keyof ResetFormData] = t("enroll_year_required");
        }
        if (day != "" && month != "" && month != "00" && year != "") {
          var validDate = moment(resetFormData.birthDate);
          const dobDate = moment(resetFormData.birthDate, "YYYY-MM-DD");
          const minAllowedDate = moment().subtract(2, "years");
          if (dobDate.isAfter(minAllowedDate)) {
            newErrors[key as keyof ResetFormData] = t("enroll_age_validation");
          } else if (!validDate.isValid()) {
            newErrors[key as keyof ResetFormData] = t("label_valid_dob_err");
          }
        }
      }
    });
    setErrors(newErrors);

    // Checking whether there is no any errors
    if (Object.keys(newErrors).length === 0) {
      setIsValid(true);
      dispatch(resetDataRequest(resetFormData));
    }
  };

  // Function to display the validation of empty input fields
  const getFieldLabel = (fieldName: keyof ResetFormData) => {
    const labels: { [key in keyof ResetFormData]: string } = {
      emailAddress: t("signin_email_required"),
      name: t("error_reset_first_name_required"),
      surname: t("error_reset_last_name_required"),
      birthDate: t("error_reset_dob_required"),
    };
    return labels[fieldName];
  };

  // Regex for validating whether the Email is in the specified format
  const isValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  // Function to manage input fields data
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...resetFormData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  // Function to manage calendar for selecting date of birth
  const handleDateChange = (date: string, month: string, year: string) => {
    const formattedDob = `${year}-${month}-${date}`;
    setFormData({ ...resetFormData, birthDate: formattedDob });
    setErrors({ ...errors, birthDate: "" });
  };

  // Function to handle errorCodes from API
  const handleErrorCode = () => {
    const errorCode =
      response.error?.errorCode || response.ResetDetails?.errorCode;
    switch (errorCode) {
      case "1002":
      case "1003":
      case "1004":
      case "1005":
      case "1006":
        return t("unlock_Account_profile_Validation");
      default:
        return t("error_reset_page_failed");
    }
  };

  return (
    <ResetUnlockBanner imageText={t("label_unlock_account")}>
      {!isSuccess && (
        <>
          <div className={styles.label}>
            <span>{t("label_reset_text")}</span>
          </div>
          {/* Message to be displayed when user enters wrong data which is not registered with TG */}
          {error && (
            <div className={styles.errorContainer}>
              <TGIcon icon="error-icon" fillColor="" size="" />
              <span className={styles.errorLabel}>{handleErrorCode()}</span>
            </div>
          )}
          <div className={styles.formContainer}>
            <div className={styles.inputContainer}>
              <TGInput
                label={t("label_unlock_first_name")}
                value={resetFormData.name}
                onChange={handleChange}
                isError={!!errors.name}
                name="name"
                maxLength={40}
                errorLabel={errors.name}
                customClassName={styles.fieldContainer}
                validation="name"
              />
              <TGInput
                label={t("label_manage_last_name")}
                value={resetFormData.surname}
                onChange={handleChange}
                name="surname"
                maxLength={40}
                isError={!!errors.surname}
                errorLabel={errors.surname}
                customClassName={styles.fieldContainer}
                validation="name"
              />
            </div>
            <div className={styles.inputContainer}>
              <TGInput
                label={t("label_reset_email")}
                value={resetFormData.emailAddress}
                onChange={handleChange}
                name="emailAddress"
                isError={!!errors.emailAddress}
                errorLabel={errors.emailAddress}
                customClassName={styles.fieldContainer}
                placeholder={t("label_reset_email_placeholder")}
                validation="language"
              />
              <TGDateDropdown
                label={t("label_reset_dob")}
                isError={!!errors.birthDate}
                onDateChange={handleDateChange}
                errorLabel={errors.birthDate}
              />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <TGButtonVariants
              label={response.isLoading ? "" : t("button_reset_continue")}
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="30px"
              boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
              bgColor="#684B9B"
              padding="16px"
              textColor="#FFFFFF"
              fontFamily="Inter"
              fontSize="16px"
              fontStyle="normal"
              fontWeight="700"
              lineHeight="160%"
              borderDisabled="0.791px solid #E3D4FE"
              bgColorDisabled="#E3D4FE"
              boxShadowDisabled="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
              bgColorHover="#684b9b"
              borderHover="0.791px solid #E3D4FE"
              boxSHadowHover="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
              width="10.6875rem"
              height="3.81rem"
              onClick={handleSubmitUnlock}
              disabled={response.isLoading}
            >
              {response.isLoading ? (
                <>
                  <Spinner
                    animation="grow"
                    variant="light"
                    style={{ width: "10px", height: "10px", margin: "5px" }}
                  />
                  <Spinner
                    animation="grow"
                    variant="light"
                    style={{ width: "10px", height: "10px", margin: "5px" }}
                  />
                  <Spinner
                    animation="grow"
                    variant="light"
                    style={{ width: "10px", height: "10px", margin: "5px" }}
                  />
                </>
              ) : null}
            </TGButtonVariants>
          </div>
        </>
      )}
      {/* If the condition got success, page has to be redirect to Success page */}
      {isSuccess && <Success />}
    </ResetUnlockBanner>
  );
};

export default UnlockAccountForm;
