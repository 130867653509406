import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import styles from "./side-bar-widget-new.module.scss";
import ManageBooking from "../booking-widget/manage-booking";
import CheckIn from "../booking-widget/check-in";
import { BookFlightMainTabSections } from "../../../../utils/enum-helper";
import { useTranslation } from "react-i18next";
import FlightStatus from "../booking-widget/flight-status";
import TGIcon from "../../../../shared/tg-icon";
import Header from "../header/index";
import Navigation from "../navigation/index";
import { useSelector } from "react-redux";
import { RootState } from "../../slice//RootReducer";
import useScreenSize from "../../../../utils/hook/useScreenSize";
import MoreServices from "../../component/booking-widget/more-services";

function MobileBottomTab() {
  const [showManageBooking, setShowManageBooking] = useState(false);
  const [showCheckIn, setShowCheckIn] = useState(false);
  const [showFlightStatus, setShowFlightStatus] = useState(false);
  const [showMoreServices, setShowMoreServices] = useState(false);
  const [label, setLabel] = useState("");
  const [miniProfileShow, setMiniProfileShow] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const [showToastTripCard, setShowToastTripCard] = useState(false);
  const toast = useSelector((state: RootState) => state?.osciReducer?.deleteTripCard);
  let isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  const [errorToast, setErrorToast] = useState(false);

  //Trip Card Deletion Toast Message
  useEffect(() => {
    if (!toast?.isLoading) {
      if (toast?.tripCardStatus.status) {
        setShowToastTripCard(true);
      }
    }
    setTimeout(() => {
      setShowToastTripCard(false);
    }, 3000);
  }, [toast]);
  useEffect(() => {
    if (!toast?.isLoading && toast?.error?.status === 400) {
      setErrorToast(true);
    }
    setTimeout(() => {
      setErrorToast(false);
    }, 3000);
  }, [toast]);

  const handleCloseManageBooking = () => {
    setShowManageBooking(false);
    document.documentElement.style.setProperty("--body-overflow", "auto");
  };
  const handleCloseCheckIn = () => {
    setShowCheckIn(false);
    document.documentElement.style.setProperty("--body-overflow", "auto");
  };
  const handleCloseFlightStatus = () => {
    setShowFlightStatus(false);
    document.documentElement.style.setProperty("--body-overflow", "auto");
  };
  const handleCloseMoreServices = () => {
    setShowMoreServices(false);
    document.documentElement.style.setProperty("--body-overflow", "auto");
  };
  const backgroundScrollHide = () => {
    document.documentElement.style.setProperty("--body-overflow", "hidden");
  };

  const handleModalBookingOpen = (e: any, label: string) => {
    if (label === BookFlightMainTabSections.ManageBooking) {
      setShowManageBooking(true);
    } else if (label === BookFlightMainTabSections.CheckIn) {
      setShowCheckIn(true);
    } else if (label === BookFlightMainTabSections.FlightStatus) {
      setShowFlightStatus(true);
    } else if (label === BookFlightMainTabSections.MoreServices) {
      setShowMoreServices(true);
    }
    setLabel(label);
  };

  const closeMiniProfile = () => {
    setMiniProfileShow(false);
  };

  return (
    <div>
      <div className={`${styles.card} global-content-margin`}>
        <div className={styles.container}>
          <div className={styles.actions}>
            <div
              className={styles.actionsItemContainer}
              onClick={(e) =>
                handleModalBookingOpen(
                  e,
                  BookFlightMainTabSections.ManageBooking
                )
              }
             >
              <div className={styles.iconContainer}>
                <TGIcon
                  icon="manage-booking-mobile"
                  size="24px"
                  fillColor=""
                ></TGIcon>
              </div>
              <span
                className={styles.textcontainer}
              >
                {t("label_book_widget_manage_booking")}
              </span>
            </div>

            <div
              className={styles.actionsItemContainer}
              onClick={(e) =>
                handleModalBookingOpen(e, BookFlightMainTabSections.CheckIn)
              }
             >
              <div className={styles.iconContainer}>
                <TGIcon
                  icon="check-in-mobile-icon"
                  size="24px"
                  fillColor=""
                ></TGIcon>
              </div>
              <span
                className={styles.textcontainer}
              >
                {t("label_book_widget_check_in")}
              </span>
            </div>

            <div
              className={styles.actionsItemContainer}
              onClick={(e) =>
                handleModalBookingOpen(
                  e,
                  BookFlightMainTabSections.FlightStatus
                )
              }
             >
              <div className={styles.iconContainer}>
                <TGIcon
                  icon="flight-status-icon"
                  size="24px"
                  fillColor=""
                ></TGIcon>
              </div>
              <span
                className={styles.textcontainer}
              >
                {t("label_book_widget_flight_status")}
              </span>
            </div>
            <div
              className={styles.actionsItemContainer}
              onClick={(e) =>
                handleModalBookingOpen(
                  e,
                  BookFlightMainTabSections.MoreServices
                )
              }
             >
              <div className={styles.iconContainer}>
                <TGIcon icon="more-icon" size="24px" fillColor=""></TGIcon>
              </div>
              <span
                className={styles.textcontainer}
              >
                {t("label_book_widget_more_services")}
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* Manage Booking */}
      {label === BookFlightMainTabSections.ManageBooking && (
        <Offcanvas
          className={styles.sidebar}
          show={showManageBooking}
          onHide={handleCloseManageBooking}
          onShow={backgroundScrollHide}
        >
          {!isDesktopView && showToastTripCard && (
            <div className={styles.toastMobile}>
              <TGIcon icon="toast-icon" fillColor="" size="" />
              {t("delete_trip_card_toast")}
            </div>
          )}
          {!isDesktopView &&
            toast?.tripCardStatus?.status === false &&
            errorToast && (
              <div className={styles.toastMobile}>
                <TGIcon icon="failure-toast-icon" fillColor="" size="" />
                {t("label_something_went_wrong")}
              </div>
            )}
          <div
            className={`${styles.navigationTopPadding} global-content-padding`}
          >
            <Navigation miniProfileClose={closeMiniProfile} />
          </div>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className={styles.font}>
              {BookFlightMainTabSections.ManageBooking.toUpperCase()}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ManageBooking />
          </Offcanvas.Body>
        </Offcanvas>
      )}
      {/* CheckIn */}
      {label === BookFlightMainTabSections.CheckIn && (
        <Offcanvas
          className={styles.sidebar}
          show={showCheckIn}
          onHide={handleCloseCheckIn}
          onShow={backgroundScrollHide}
        >
          {!isDesktopView && showToastTripCard && (
            <div className={styles.toastMobile}>
              <TGIcon icon="toast-icon" fillColor="" size="" />
              {t("delete_trip_card_toast")}
            </div>
          )}
          {!isDesktopView &&
            toast?.tripCardStatus?.status === false &&
            errorToast && (
              <div className={styles.toastMobile}>
                <TGIcon icon="failure-toast-icon" fillColor="" size="" />
                {t("label_something_went_wrong")}
              </div>
            )}
          <div
            className={`${styles.navigationTopPadding} global-content-padding`}
          >
            <Navigation miniProfileClose={closeMiniProfile} />
          </div>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className={styles.font}>
              {BookFlightMainTabSections.CheckIn.toUpperCase()}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <CheckIn />
          </Offcanvas.Body>
        </Offcanvas>
      )}
      {/* FlightStatus */}
      {label === BookFlightMainTabSections.FlightStatus && (
        <Offcanvas
          className={styles.sidebar}
          show={showFlightStatus}
          onHide={handleCloseFlightStatus}
          onShow={backgroundScrollHide}
        >
          {!isDesktopView && showToastTripCard && (
            <div className={styles.toastMobile}>
              <TGIcon icon="toast-icon" fillColor="" size="" />
              {t("delete_trip_card_toast")}
            </div>
          )}
          {!isDesktopView &&
            toast?.tripCardStatus?.status === false &&
            errorToast && (
              <div className={styles.toastMobile}>
                <TGIcon icon="failure-toast-icon" fillColor="" size="" />
                {t("label_something_went_wrong")}
              </div>
            )}
          <div
            className={`${styles.navigationTopPadding} global-content-padding`}
          >
            <Navigation miniProfileClose={closeMiniProfile} />
          </div>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className={styles.font}>
              {BookFlightMainTabSections.FlightStatus.toUpperCase()}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <FlightStatus />
          </Offcanvas.Body>
        </Offcanvas>
      )}
      {/* MoreServices */}
      {label === BookFlightMainTabSections.MoreServices && (
        <Offcanvas
          className={`${styles.sidebar}`}
          show={showMoreServices}
          onHide={handleCloseMoreServices}
          onShow={backgroundScrollHide}
        >
          {!isDesktopView && showToastTripCard && (
            <div className={styles.toastMobile}>
              <TGIcon icon="toast-icon" fillColor="" size="" />
              {t("delete_trip_card_toast")}
            </div>
          )}
          {!isDesktopView &&
            toast?.tripCardStatus?.status === false &&
            errorToast && (
              <div className={styles.toastMobile}>
                <TGIcon icon="failure-toast-icon" fillColor="" size="" />
                {t("label_something_went_wrong")}
              </div>
            )}
          <div
            className={`${styles.navigationTopPadding} global-content-padding`}
          >
            <Navigation miniProfileClose={closeMiniProfile} />
          </div>
          <Offcanvas.Header className={styles.headerMoreServices} closeButton>
            <Offcanvas.Title className={styles.font}>
              {BookFlightMainTabSections.MoreServices.toUpperCase()}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className={styles.bodyMoreServices}>
            <MoreServices />
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </div>
  );
}

export default MobileBottomTab;
