import React, { useEffect } from "react";
import styles from "./sawasdee.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { SawasdeeMagData } from "../../models/models";
import { RootState } from "../../slice/RootReducer";
import {
  getSWDataFailure,
  getSWDataSuccess,
} from "../../slice/sawasdeeMagSlice";
import TGButtonVariants from "../../../../shared/tg-button-variants";
import { config } from "../../../../config/global";

export const SawasdeeCard = () => {
  const dispatch = useDispatch();
  const swMagzine = useSelector(
    (state: RootState) => state?.osciReducer?.swMagzine.SawasdeeMagDetails
  );
  const API_URL = config.TEAMSITE_SAWASDEECARD_ENDPOINT;

  useEffect(() => {
    dispatch({
      type: config.FETCH_DATA_CONSTANT,
      url: API_URL,
      isTeamsite: true,
      successAction: getSWDataSuccess,
      errorAction: getSWDataFailure,
    });
    Sawasdee();
  }, [dispatch]);

  const handleNavigate = (url: string) => {
    window.open(url);
  };

  function Sawasdee() {
    return swMagzine?.map((x: SawasdeeMagData) => (
      <div
        className={`promoCard ${styles.imageContainer}`}
        onClick={() => handleNavigate(x.reDirUrl)}
      >
        <img
          src={x.image_1}
          alt="Magazine-1"
          className={`promoImage ${styles.magazine1}`}
        />
        <img
          src={x.image_2}
          alt="Magazine-2"
          className={`promoImage ${styles.magazine2}`}
        />
        <div className={styles.hoverContent}>
          <span className={styles.yearText}>{x.year}</span>
          <span className={styles.seasonText} role="heading">
            {x.year_desc}
          </span>
          <div className={styles.btn}>
            <TGButtonVariants
              label={x.reDirText}
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="30px"
              boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
              bgColor="#FFFFFF"
              padding="8px 12px 8px 16px"
              textColor="#684B9B"
              fontFamily="Inter"
              fontSize="14px"
              fontStyle="normal"
              fontWeight="700"
              lineHeight="160%"
              height="42px"
              width="120px"
              bgColorHover="#b3b2b6"
              onClick={() => handleNavigate(x.reDirUrl)}
            />
          </div>
        </div>
      </div>
    ));
  }

  return <Sawasdee />;
};
